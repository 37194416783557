<template>
	<div id="laowu">
		<div class="hzBanner"></div>
		<div class="hzCont">
			<div class="tabs">
				<template v-for="(item,i) in list">
					<p :class="item.class == curNow ? 'active':''" :key="i" @click="tabsChange(item)">
						{{item.name}}
						<img src="../../assets/imgs/wei.png" alt="">
					</p>
					<el-divider direction="vertical" :key="i" v-if="i<list.length-1"></el-divider>
				</template>
			</div>
			<!-- <div class="searth">
				<el-row :gutter="20">
					<el-col :span="12">
						<el-input clearable placeholder="输入项目/会议主题" v-model="input" class="input-with-select">
							<i slot="prefix" class="el-input__icon el-icon-search"></i>
						</el-input>
					</el-col>
					<el-col :span="12">
						<el-date-picker v-model="value" type="daterange" align="right" unlink-panels range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
							style="width: 100%;">
						</el-date-picker>
					</el-col>
				</el-row>
			</div> -->
			<div class="list">
				<div class="item" v-for="item in patientList" :key="item.id" @click="toMeetingDetails(item)">
					<div class="main">
						<div class="info">
							<div class="title">主题：{{item.meetingTitle}}</div>
							<div class="time">会议时间：
								{{$moment(item.meetingStartTime).format('L') }}
								{{$moment(item.meetingStartTime).format('LT') }}
							</div>
						</div>
						<div class="price">
							<div class="num">￥{{item.afterTax}}</div>
							<div class="type succ" v-if="item.status == 'WAIT'">等待支付</div>
							<div class="type lose" v-if="item.status == 'ERROR'">支付失败</div>
							<div class="type succ" v-if="item.status == 'FINISH'">支付完成</div>
						</div>
					</div>
					<div class="option" v-if="item.status == 'ERROR'">
						<el-button>查看问题</el-button>
					</div>
				</div>
				<div class="null" v-if="!patientList[0]">
					<img src="../../assets/imgs/EmptyState.png" alt="">
					<p>暂时没有会议内容</p>
				</div>
			</div>
			<el-pagination background layout="prev,pager,next" :page-size="size" :total="total"
				:current-page.sync="page" @current-change="myPatientFun" :hide-on-single-page="false"></el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		orderList
	} from '@/request/api.js';
	export default {
		name: 'laowu',
		components: {},
		data() {
			return {
				page: 1,
				total: 0,
				size: 10,
				patientList: [],
				curNow: "WAIT",
				list: [{
						name: '待支付',
						class: 'WAIT',
					},
					{
						name: '已支付',
						class: 'FINISH',
					}
				],
				input: '',
				value: '',
				/* pickerOptions: {
					shortcuts: [{
						text: '最近三天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}]
				} */
			}
		},
		created() {
			this.myPatientFun();
		},
		mounted() {},
		methods: {
			viewTheProblem(item) {
				this.$router.push({
					name: 'huiyiDetails',
					params: {
						id: item
					}
				});
			},
			// 切换会诊选项
			tabsChange(item) {
				this.curNow = item.class;
				this.status = item.meetingStatus;
				this.myPatientFun();
			},
			// 查询laowu列表
			myPatientFun() {
				let data = {
					current: this.page,
					size: this.size,
					status: this.curNow
				};
				orderList(data).then(res => {
					if (res.code == 200) {
						this.patientList = res.data.records
						this.total = res.data.total;
					};
				});
			},
			toMeetingDetails(item) {
				this.$router.push({
					name: 'dmeetdetail',
					query: {
						mId: item.meetingId
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#laowu {
		display: flex;
		flex-direction: column;

		.hzBanner {
			min-height: 140px;
			background-color: rgba(20, 71, 205, 1);
		}

		.hzCont {
			flex: 1;
			padding: 20px;
			display: flex;
			margin-top: -20px;
			background-color: #fff;
			flex-direction: column;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;

			.tabs {
				display: flex;
				margin-bottom: 20px;
				flex-direction: row;
				align-items: center;
				padding-bottom: 20px;
				justify-content: space-around;
				border-bottom: 2px solid #f3f3f3;

				.el-divider {
					width: 2px;
					height: 30px;
				}

				p {
					padding: 5px;
					font-size: 18px;
					cursor: pointer;
					line-height: 2.5;
					position: relative;

					img {
						width: 0;
						left: 50%;
						bottom: 0;
						height: 0;
						position: absolute;
						transition: all .3s;
						transform: translate(-50%, 50%);
					}
				}

				.active img {
					width: 60px;
					height: 24px;
				}
			}

			.searth {
				margin-bottom: 30px;
			}
		}

		.list {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			flex-direction: row;

			.null {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				padding: 100px;

				img {
					width: 200px;
				}

				p {
					font-size: 18px;
					color: #A6A6A6;
				}
			}
		}

		.item {
			width: 100%;
			border-radius: 10px;
			transition: all .3s;
			margin-bottom: 20px;
			box-shadow: 0px 2px 13px rgba(203, 203, 203, 0.31);
			min-height: 50px;
			display: flex;
			flex-direction: column;
			padding: 20px;

			&:hover {
				background: rgba(244, 249, 253, 1);
			}

			.main {
				display: flex;
				align-items: center;

				.info {
					flex: 1;

					.title {
						font-size: 16px;
						color: #333;
						margin-bottom: 10px;
					}

					.time {
						font-size: 15px;
						color: #333;
					}
				}

				.price {
					margin-left: 20px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					font-size: 24px;
					color: #0065FF;

					.type {
						font-size: 13px;
					}

					.suss {
						color: #2A82E4;
					}

					.lose {
						color: #D43030;
					}
				}
			}

			.option {
				margin-top: 10px;
				display: flex;
				justify-content: flex-end;

				.el-button {
					background-color: #D43030;
					color: #fff;
					padding: 0 24px;
					height: 28px;
				}
			}
		}

		.el-pagination {
			margin: auto;
		}
	}
</style>